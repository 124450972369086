import React from "react";
import HeroSection from "../components/HeroSection";
import "./HomePage.css"; // Import your CSS file here

function HomePage() {
  return (
    <div className="home-page">
      <div>
        <HeroSection />
        <div className="homepage-content-container">
          <h1>Welcome to My Purple Essence Portfolio</h1>
          <p>
            Dive into a world where professionalism meets elegance, all in the
            sophisticated shades of purple. Discover my journey, explore my
            projects, and feel free to reach out. Let's connect and create
            something remarkable together.
          </p>

          {/* Add more content such as featured projects, skills, etc. */}
        </div>
      </div>
    </div>
  );
}

export { HomePage };
