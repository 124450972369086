import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import HeroSection from "../components/HeroSection";
import "./ContactPage.css";

function ContactPage() {
  return (
    <div className="contact-page">
      <HeroSection />
      <div className="contactpage-content-container">
        <h1>Get In Touch</h1>
        <p>
          I'm always open to discussing new projects, creative ideas, or
          opportunities. Feel free to contact me via any of the following
          methods:
        </p>
        {/* Email, LinkedIn, and GitHub Links */}
        <div className="contact-links-container">
          <a href="mailto:christiharlow@outlook.com" className="contact-link">
            <FontAwesomeIcon icon={faEnvelope} />
            Email Me
          </a>
          <a
            href="https://www.linkedin.com/in/christiaharlow"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <FontAwesomeIcon icon={faLinkedin} />
            LinkedIn
          </a>
          <a
            href="https://github.com/ChristiHarlow"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            <FontAwesomeIcon icon={faGithub} />
            GitHub
          </a>
        </div>
      </div>
    </div>
  );
}

export { ContactPage };
