import React from "react";
import "./HeroSection.css"; // Your Hero Section CSS file

function HeroSection() {
  return (
    <div className="hero-section">
      {/* Decorative Image */}
      <img src="/imagePortfolio.png" alt="Decorative" className="hero-image" />
      <div className="text-content"></div>
    </div>
  );
}

export default HeroSection;
