import React from "react";
import HeroSection from "../components/HeroSection";
import "./AboutPage.css"; // Ensure this points to your CSS file

function AboutPage() {
  return (
    <div className="about-page">
      <HeroSection />
      <div className="aboutpage-content-container">
        <h1>About Christi Harlow</h1>
        <p>
          Hello! I’m Christi, an aspiring Full-Stack Web Developer with a
          passion for creating seamless web experiences. My skill set
          encompasses a range of technologies including HTML5, CSS3, Bootstrap,
          JavaScript, and React, complemented by a growing expertise in Python,
          Java, and SQL.
        </p>
        <p>
          I enhanced my skills through a Computer Programming
          Certificate program, building on my accomplishment of completing a
          JavaScript Developer Certificate. I am dedicated to continuous
          learning and applying my knowledge to develop innovative, user-centric
          solutions.
        </p>
      </div>
    </div>
  );
}

export { AboutPage };
