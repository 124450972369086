import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Ensure this points to your CSS file

function Navbar() {
  return (
    <nav className="navbar">
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link> {/* Add this line */}
      <Link to="/resume">Resume</Link>
      {/* ... other links */}
    </nav>
  );
}

export default Navbar;
